<template>
  <div>
    <v-card max-width="600px" class="mx-auto my-12 px-6 py-4" elevation="6">
      <v-card-title class="mt-4">请选择要测量的量表</v-card-title>
      <v-card-text>
        点击选择需要测量的量表（可多选）。量表的答题顺序就是点击选中的顺序。
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-list>
          <v-list-item-group color="primary" multiple v-model="selectedLbGuids">
            <v-list-item
              v-for="lb in testLbList"
              :key="lb.value"
              :value="lb.value"
              class="mt-2"
            >
              {{ lb.text }}
              <v-spacer></v-spacer>
              {{ getLbOrderText(lb.value) }}
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="px-4"
          depressed
          @click="isShowConfirmDialog = true"
          :disabled="!selectedLbGuids.length"
        >
          选好了
        </v-btn>
      </v-card-actions>
    </v-card>
    <AppDialog
      v-model="isShowConfirmDialog"
      title="点击确定开始答题"
      color="success"
      action-icon="mdi-lead-pencil"
      action-text="开始答题"
      @confirm="startLbTests"
    >
      答题顺序：
      <ol>
        <li v-for="(lbGuid, index) in selectedLbGuids" :key="index">
          {{ `${getLbNameFromId(lbGuid)}` }}
        </li>
      </ol>
      <div class="mt-4">
        注意：量表选择后无法更改。如要修改，需要退出后重新输入测量码
      </div>
    </AppDialog>
  </div>
</template>

<script>
import AppDialog from "@/components/AppDialog";
import { mapGetters } from "vuex";

export default {
  components: {
    AppDialog
  },

  data() {
    return {
      selectedLbGuids: [],
      isShowConfirmDialog: false
    };
  },

  computed: {
    ...mapGetters({
      testLbList: "sca/testLbList"
    }),
    selectedLbList() {
      let lbList = this.selectedLbGuids.map(lbGuid =>
        this.testLbList.find(lb => lb.value === lbGuid)
      );
      return lbList.filter(lb => lb);
    }
  },

  methods: {
    getLbOrderText(lbGuid) {
      let selectedIndex = this.selectedLbGuids.indexOf(lbGuid);
      if (selectedIndex > -1) {
        return `答题顺序：${selectedIndex + 1}`;
      }
      return "";
    },
    getLbNameFromId(lbGuid) {
      let lbObj = this.testLbList.find(lb => lb.value === lbGuid);
      if (lbObj) {
        return lbObj.text;
      }
      return "";
    },
    startLbTests() {
      // 重新设定 testLbList
      this.$store.dispatch("sca/setTestLbList", this.selectedLbList);
      this.$router.push({ name: "scastepper" });
    }
  }
};
</script>
